import Button from './components/Button/Button'
import Canvas from './components/Canvas/Canvas'

import './App.css';


const PolygonParticles = () => {
  // const canvas = 

  class Particle { 
    constructor(x, y, directionX, directionY, size, color, canvas, ctx) {
      this.x = x
      this.y = y
      this.directionX = directionX
      this.directionY = directionY
      this.size = size
      this.color = color
      this.canvas = canvas
      this.ctx = ctx
    }

    draw(ctx, frameCount) {
      ctx.beginPath();
      ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false)
      ctx.fillStyle = '#000'
      ctx.fill()
    }

    update(ctx, frameCount) {
       if (this.x > this.canvas.width || this.x < 0) {
          this.directionX = -this.directionX
       }
      
       if (this.y > this.canvas.height || this.y < 0) {
        this.directionY = -this.directionY
      }

      this.x += this.directionX
      this.y += this.directionY
      this.draw(ctx, frameCount)
    }
  }

  let particlesArray = []
  const innerWidth = window.innerWidth
  const innerHeight = window.innerHeight

  const init = (canvas, ctx) => {
    particlesArray = []
    particlesArray.push()
    const particlesCount = innerWidth * innerHeight / 5000

    for (let i = 0; i < particlesCount; i++) {
      let size = (Math.random() * 5) + 1
      let x = (Math.random() * ((innerWidth - size * 2) - (size * 2)) + size * 2)
      let y = (Math.random() * ((innerHeight - size * 2) - (size * 2)) + size * 2)
      let directionX = ((Math.random() - 0.5) * 1.5)
      let directionY = ((Math.random() - 0.5) * 1.5)
      let color = '#000'

      particlesArray.push(new Particle(x, y, directionX, directionY, size, color, canvas, ctx))
    }

    console.log(particlesArray)
  }

  const pointConnection = (ctx, particlesArray) => {
    particlesArray.forEach((elementA) => {
      particlesArray.forEach((elementB) => {
        const distance = Math.pow((Math.pow((elementA.x - elementB.x), 2)
        + Math.pow((elementA.y - elementB.y), 2)), 0.5)

        const screenDistance = Math.pow((Math.pow((elementA.canvas.width + elementA.canvas.width), 2)
        + Math.pow((elementA.canvas.height + elementA.canvas.height), 2)), 0.5)
        
        const opacity = distance / (screenDistance / 7);

        if (distance < (screenDistance / 25)) {
          ctx.strokeStyle = 'rgba(0,0,0,' + opacity +')'
          ctx.lineWidth = 1
          ctx.beginPath()
          ctx.moveTo(elementA.x, elementA.y)
          ctx.lineTo(elementB.x, elementB.y)
          ctx.stroke()
        }
      })
    })
  }


  const draw = (ctx, frameCount) => {

    for (let i = 0; i < particlesArray.length; i++) {
      particlesArray[i].update(ctx, frameCount)
    }

    pointConnection(ctx, particlesArray)
  }

  return <Canvas 
    draw={draw} 
    init = {init}
    className='App__BgCanvas'
  />

}



function App() {

  return (
    <div className="App">

      <div className='App__ContentContainer'>
        <div className='App__Title'>3A Web</div>

        <div className='App__Subtitle'>coming soon...</div>

        {/* <div className='App__ButtonsContainer'>
          <Button title={'About'} />
          
          <Button title={'Contacts'} />

          <Button title={'About'} />
        </div> */}
      </div>


      <PolygonParticles/>

    </div>
  );
}

export default App;
