import { useEffect, useRef } from 'react';


function resizeCanvasToDisplaySize(canvas) {
    const { width, height } = canvas.getBoundingClientRect()

    if (canvas.width !== width || canvas.height !== height) {
        const { devicePixelRatio:ratio=1 } = window
        const context = canvas.getContext('2d')
        canvas.width = width*ratio
        canvas.height = height*ratio
        context.scale(ratio, ratio)
        return true
    }

    return false
}

const _predraw = (canvas, context) => {
    context.save()
    resizeCanvasToDisplaySize(canvas)
    const { width, height } = context.canvas
    context.clearRect(0, 0, width, height)
  }
  
  const _postdraw = () => { 
    // index++
    // ctx.restore()
  }
  
const useCanvas = (draw, options) => {
    const canvasRef = useRef(null)

    const {predraw, postdraw, init} = options

    useEffect(() => {
        const canvas = canvasRef.current
        const context = canvas.getContext('2d')
        
        let frameCount = 0
        let animationFrameId

        init(canvas, context)

        const render = () => {
            frameCount++
            predraw(canvas, context)
            draw(context, frameCount)
            animationFrameId = window.requestAnimationFrame(render)
        }

        render()

        return () => {
        window.cancelAnimationFrame(animationFrameId)
        }
    }, [draw])

    return canvasRef
}
  
const Canvas = props => {  
    const { draw, predraw=_predraw, postdraw=_postdraw, init, ...rest } = props
    const canvasRef = useCanvas(draw, {predraw, postdraw, init})

    return <canvas ref={canvasRef} {...rest}/>
}

export default Canvas
